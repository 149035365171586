import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/Constants';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

function AndroidReportedBlockingV2() {
  const [apiCalled, setApiCalled] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 86400000)); // Default: 7 days ago
  const [endDate, setEndDate] = useState(new Date()); // Default: current time

  const handleTicketStats = (ticketStatsList) => {
    setReportData(ticketStatsList);
  };

  const getFeedbackRatingStats = (startTime, endTime) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${BASE_URL}/api/v2/dashboard-stats/reported-blocking?startTime=${startTime}&endTime=${endTime}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('Data : ', data);
        if (data.status === 200) {
          setApiCalled(true);
          handleTicketStats(data.data.list);
        }
      })
      .catch((error) => {
        console.log('Error : ', error);
      });
  };

  const handleSave = () => {
    const startTime = new Date(startDate).getTime();
    const endTime = new Date(endDate).getTime();
    getFeedbackRatingStats(startTime, endTime);
  };

  useEffect(() => {
    if (!apiCalled) {
      const currentTime = new Date().getTime();
      const sevenDaysAgo = currentTime - 7 * 86400000;

      getFeedbackRatingStats(sevenDaysAgo, currentTime);
    }
  }, [apiCalled]);

  return (
    <>
      <div className='container mx-auto px-4'>
        <p className='text-center text-4xl font-bold mt-12'>Android Reported Blocking</p>

        <div className='flex justify-center mt-8'>
          <div className='flex flex-col'>
            <label className='text-xl'>Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              className="border p-2 rounded"
            />
          </div>

          <div className='flex flex-col mx-8'>
            <label className='text-xl'>End Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              className="border p-2 rounded"
            />
          </div>

          <button
            onClick={handleSave}
            className='bg-blue-500 text-white px-6 py-2 rounded self-center mt-6'>
            Save
          </button>
        </div>

        {reportData.length > 0 && (
          <div className='overflow-x-auto pt-8'>
            <table className='min-w-full table-auto border-collapse'>
              <thead>
                <tr className='bg-gray-100'>
                  <th className='px-6 py-3 text-left text-lg font-medium text-gray-700'>App</th>
                  <th className='px-6 py-3 text-left text-lg font-medium text-gray-700'>Unique Keywords</th>
                  <th className='px-6 py-3 text-left text-lg font-medium text-gray-700'>Top 20 keywords</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((appData) => (
                  <tr key={appData.app} className='border-b'>
                    <td className='px-6 py-4 text-gray-800'>{appData.app}</td>
                    <td className='px-6 py-4 text-gray-800'>{appData.count}</td>
                    <td className='px-6 py-4 text-gray-800'>
                      <ul className='list-disc pl-5'>
                        {appData.names.map((nameData) => (
                          <li key={nameData.name} className='text-sm'>
                            {nameData.name} ({nameData.count})
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default AndroidReportedBlockingV2;
