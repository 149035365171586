import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../../api/Constants';

function SpamTickets() {
    const [email, setEmail] = useState('');
    const [spamEmails, setSpamEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to handle the email submission
    const handleSpamUser = () => {
        if (!email) {
            alert('Please enter an email address');
            return;
        }

        setLoading(true);
        setError(null);

        // Call the API to mark the email as spam
        fetch(`${BASE_URL}/api/v1/tickets-ops/spam-users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'success') {
                    // Fetch the list of spam emails after marking
                    fetchSpamEmails();
                    setEmail('')
                } else {
                    setError(result.message || 'Failed to mark as spam');
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                setError('Failed to mark as spam');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Function to fetch the list of spam emails
    const fetchSpamEmails = () => {
        fetch(`${BASE_URL}/api/v1/tickets-ops/spam-users`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'success') {
                    setSpamEmails(result.data); // Set the list of spam emails
                } else {
                    setError(result.message || 'Failed to fetch spam emails');
                }
            })
            .catch((error) => {
                console.log('Error fetching spam emails:', error);
                setError('Failed to fetch spam emails');
            });
    };

    // Function to remove a user from the spam list
    const removeUserFromSpamList = (email) => {
        fetch(`${BASE_URL}/api/v1/tickets-ops/spam-users/${email}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'success') {
                    fetchSpamEmails(); // Refresh the spam email list after removal
                } else {
                    setError(result.message || 'Failed to delete user from spam list');
                }
            })
            .catch((error) => {
                console.log('Error deleting user from spam list:', error);
                setError('Failed to delete user from spam list');
            });
    };

    // Fetch the spam emails list when the component mounts
    useEffect(() => {
        fetchSpamEmails();
    }, []);

    return (
        <div className="p-12 rounded-lg bg-white">
            <div className="text-xl font-bold py-4">Spam User Management</div>
            <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700">Enter Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-2 px-4 py-2 w-full border rounded"
                    placeholder="Enter email to mark as spam"
                />
            </div>
            <button
                onClick={handleSpamUser}
                disabled={loading}
                className={`px-6 py-2 mt-4 text-white rounded ${loading ? 'bg-gray-400' : 'bg-red-600 hover:bg-red-700'}`}
            >
                {loading ? 'Processing...' : 'Spam User'}
            </button>

            {error && <div className="text-red-500 mt-4">{error}</div>}

            <div className="mt-6">
                <h3 className="text-lg font-semibold">Spam Emails List:</h3>
                <ul className="list-disc pl-6 mt-2">
                    {spamEmails.length > 0 ? (
                        spamEmails.map((spamUser, index) => (
                            <li key={index} className="text-gray-700">
                                {spamUser.email}
                                <button
                                    onClick={() => removeUserFromSpamList(spamUser.email)}
                                    className="ml-4 text-red-500"
                                >
                                    Remove
                                </button>
                            </li>
                        ))
                    ) : (
                        <li>No spam emails yet</li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default SpamTickets;
