import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/Constants';
import { showTimeInAgoFormat } from '../../../Utils/time';

function ReportedBlockingFeedbacks() {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchFeedbackReports = () => {
        setLoading(true);
        fetch(`${BASE_URL}/api/v1/reported-block-data/feedback-reports`)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setReports(data.data);
                } else {
                    setError('Failed to fetch reports');
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching reports:', error);
                setError('An error occurred while fetching reports.');
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchFeedbackReports();
    }, []);

    return (
        <div className="container mx-auto px-4">
            <p className="text-center text-4xl font-bold mt-12">Feedback Reports</p>

            {loading && <p className="text-center mt-4">Loading...</p>}
            {error && <p className="text-center text-red-500 mt-4">{error}</p>}

            {!loading && !error && reports.length > 0 && (
                <div className="overflow-x-auto py-16">
                    <table className="min-w-full table-auto border-collapse">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">App</th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Keyword</th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Feedback</th>
                                <th className="px-6 py-3 text-left text-lg font-medium text-gray-700">Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.map((report) => (
                                <tr key={report._id} className="border-b">
                                    <td className="px-6 py-4 text-gray-800">{report.app}</td>
                                    <td className="px-6 py-4 text-gray-800">{report.name}</td>
                                    <td className="px-6 py-4 text-gray-800">{report.feedback}</td>
                                    <td className="px-6 py-4 text-gray-800">
                                        {showTimeInAgoFormat(report.time)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {!loading && reports.length === 0 && <p className="text-center mt-4">No reports found.</p>}
        </div>
    );
}

export default ReportedBlockingFeedbacks;
