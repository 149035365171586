import React, { useEffect, useState } from 'react'
import { NEW_APPS_BASE_URL } from '../../../api/Constants'

function WebsiteBlockerTopBlocklistWebsites() {
    const [websites, setWebsites] = useState([])

    const getBlockedWebsites = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }

        fetch(`${NEW_APPS_BASE_URL}/api/v1/website-blocker/stats/websites-stats?type=blocklist`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setWebsites(data.data.list)
                }
            })
            .catch((error) => {
                console.log('Error:', error)
            })
    }

    useEffect(() => {
        getBlockedWebsites()
    }, [])

    return (
        <div className="overflow-x-auto py-6 px-4">
            <p className="text-center text-2xl text-gray-500 font-bold mt-12">Top Blocked Websites</p>
            <div className="mt-8 max-w-10xl overflow-hidden bg-white shadow-md sm:rounded-lg">
                <table className="mx-auto divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Website
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Blocked Count
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {websites.map((website, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{website._id}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{website.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WebsiteBlockerTopBlocklistWebsites
