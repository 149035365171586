/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter, getBooleanString, isValidEmail, transformSwitchName } from '../../../Utils/string'
import secureLocalStorage from 'react-secure-storage'
import Button from '@mui/material/Button'
import MuiAlert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import { Chip, Input, Modal, Switch, TextField } from '@mui/material'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import ClockIcon from '../../../assets/images/clock.png'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import WarningIcon from '../../../assets/images/warning.png'
import { Edit, WorkspacePremium } from '@mui/icons-material'
import { convertMillisecondsToDate, showTimeInAgoFormat } from '../../../Utils/time'
import { isObjectEmpty } from '../../../Utils/Object'
import { BASE_URL } from '../../../api/Constants'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function UserInfoPage() {
    const location = useLocation()
    const navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')
    const [apiCalled, setApiCalled] = useState(false)
    const [userIdentifier, setUserIdentifier] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [showLoading, setShowLoading] = useState(true)
    const [userId, setUserId] = useState('')
    const [showErrorScreen, setShowErrorScreen] = useState(false)

    const [userDoc, setUserDoc] = useState({
        uid: '',
        premium: {
            status: 'Active',
            orderId: 'Not present',
            planName: '',
            purchaseToken: '',
            paymentMethod: 'stripeUSA'
        },
        username: '',
        email: '',
        partner: 'Not present',
        partnerOption: 'Not present',
        lastActive: 1705744307970,
        device: 'Not present',
        iosDevice: 'Not present',
        appVersion: "",
        deviceOsVersion: 'Not present',
        iosAppVersion: 'Not present',
        iosDeviceOsVersion: 'Not present',
        country: 'IN',
        language: 'en',
        installedAt: 1650712559000,
        forceUninstall: false,
        forceVpn: false,
        forceUnsupportedBrowser: false,
        forceBuddyRemove: false,
        forceNewlyInstalledSwitch: false,
        forceInstantApproval: false,
        forceChangeBuddyRemove: false,
        forceBuddyTroubleRemove: false,
        installationSource: 'Unknown',
        chrome: true,
        isMac: false,
        blockerxPro: {},
        switches: {
            ios: {},
            android: {}
        },
        mac: {

        },
        pornRequest: 'off',
        hasActiveStreak: false
    })

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success and Error alerts
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const getUserDetails = (UID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: UID, dashboardUid: dashboardUid })
        }
        fetch('https://dev.blockerx.net/api/dashboard/userinfo', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setUserDoc(data.data.user)
                    setShowLoading(false)
                    if (data.data.user.appVersion === '0000') {
                        setErrorMessage('Invalid User ID')
                        setShowErrorScreen(true)
                        return
                    } else {
                        setShowLoading(false)
                        setShowErrorScreen(false)
                    }
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 403) {
                    secureLocalStorage.clear()
                    navigate('/forbidden')
                } else {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                }
            })
            .catch((error) => { })
    }

    const showInstallationSource = (source) => {
        if (source === 'playStore') {
            return 'Google Play Store'
        } else if (source === 'blockerxWeb') {
            return 'APK'
        } else {
            return 'Unknown Source'
        }
    }

    const getUserInformation = async (identifier) => {
        if (identifier.trim() === '') {
            setErrorMessage('Invalid user identifier')
            setOpenErrorAlert(true)
            return
        }

        if (isValidEmail(identifier)) {
            console.log('Inside valid mail')
            setUserEmail(identifier)
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
                body: JSON.stringify({ email: identifier, dashboardUid: secureLocalStorage.getItem('uid') })
            }

            fetch('https://healify.blockerx.net/api/dashboard/firebase/userInfo', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        setUserId(data.data.uid)
                        getUserDetails(data.data.uid)
                    } else if (data.status === 'invalid_token') {
                        secureLocalStorage.clear()
                        navigate('/tokenExpired')
                    } else {
                        setErrorMessage('User not found!')
                        setOpenErrorAlert(true)
                        setShowErrorScreen(true)
                    }
                })
        } else {
            if (identifier.length < 10) {
                setErrorMessage('Invalid User ID')
                setShowErrorScreen(true)
                return
            }
            setUserId(identifier)
            getUserDetails(identifier)
        }
    }

    const getLanguageOfUser = () => {
        try {
            const languageNames = new Intl.DisplayNames(['en'], {
                type: 'language'
            })
            return languageNames.of(userDoc.language)
        } catch {
            return 'English'
        }
    }

    const getCountryOfUser = () => {
        try {
            const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
            return regionNames.of(userDoc.country)
        } catch {
            return 'USA'
        }
    }

    const copyUserMailHandler = (value) => {
        navigator.clipboard.writeText(value)
        setSuccessMessage(`${value} has been copied to clipboard!`)
        setOpenSuccessAlert(true)
    }

    const handlePreventUninstallStatus = () => {
        const updatedUserDoc = {
            ...userDoc,
            forceUninstall: !userDoc.forceUninstall
        }

        setUserDoc(updatedUserDoc)
        changePreventUninstallStatusOfUser(userId, !userDoc.forceUninstall)
    }

    const handleForceVpnStatus = () => {
        const updatedUserDoc = {
            ...userDoc,
            forceVpn: !userDoc.forceVpn
        }

        setUserDoc(updatedUserDoc)
        changeForceVPNSwitchOfUser(userId, !userDoc.forceVpn)
    }

    const changeForceVPNSwitchOfUser = (uid, newStatus) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: uid, switchName: 'forceVpnOff', flag: newStatus, secretKey: 'friendly-bohr' })
        }
        fetch('https://dev.blockerx.net/api/updateForceSwitches', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Force switch ddddata : ', data)
            })
            .catch((error) => { })
    }

    // Instant Approval
    const handleForceInstantApprovalSwitch = () => {
        const updatedUserDoc = {
            ...userDoc,
            forceInstantApproval: !userDoc.forceInstantApproval
        }

        setUserDoc(updatedUserDoc)
        changeForceInstantApprovalSwitch(userId, !userDoc.forceInstantApproval)
    }

    const handleForceChangeBuddyRemove = () => {
        const updatedUserDoc = {
            ...userDoc,
            forceChangeBuddyRemove: !userDoc.forceChangeBuddyRemove
        }

        setUserDoc(updatedUserDoc)
        changeForceButtonCommon(userId, 'forceChangeBuddyRemove', !userDoc.forceChangeBuddyRemove)
    }

    const handleForceBuddyTroubleRemove = () => {
        const updatedUserDoc = {
            ...userDoc,
            forceBuddyTroubleRemove: !userDoc.forceBuddyTroubleRemove
        }

        setUserDoc(updatedUserDoc)
        changeForceButtonCommon(userId, 'forceBuddyTroubleRemove', !userDoc.forceBuddyTroubleRemove)
    }

    const changeForceInstantApprovalSwitch = (uid, newStatus) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: uid, switchName: 'forceInstantApproval', flag: newStatus, secretKey: 'friendly-bohr' })
        }
        fetch('https://dev.blockerx.net/api/updateForceSwitches', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Force switch ddddata : ', data)
            })
            .catch((error) => { })
    }


    const handleForceUnsupportedBrowserStatus = () => {
        const updatedUserDoc = {
            ...userDoc,
            forceUnsupportedBrowser: !userDoc.forceUnsupportedBrowser
        }

        setUserDoc(updatedUserDoc)
        changeForceUnsupportedBroswerOfUser(userId, !userDoc.forceUnsupportedBrowser)
    }

    const handleForceBuddyRemoveStatus = () => {
        const updatedUserDoc = {
            ...userDoc,
            forceBuddyRemove: !userDoc.forceBuddyRemove
        }

        setUserDoc(updatedUserDoc)
        changeForceBuddyRemoveButtonOfUser(userId, !userDoc.forceBuddyRemove)
    }

    const handleForceNewlyInstalledStatus = () => {
        const updatedUserDoc = {
            ...userDoc,
            forceNewlyInstalledSwitch: !userDoc.forceNewlyInstalledSwitch
        }

        setUserDoc(updatedUserDoc)
        changeForceNewlyInstalledSwitch(userId, !userDoc.forceNewlyInstalledSwitch)
    }

    const changeForceUnsupportedBroswerOfUser = (uid, newStatus) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: uid, switchName: 'forceUnsupportedBrowser', flag: newStatus, secretKey: 'friendly-bohr' })
        }
        fetch('https://dev.blockerx.net/api/updateForceSwitches', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Force switch ddddata : ', data)
            })
            .catch((error) => { })
    }

    const changeForceButtonCommon = (uid, switchName, newStatus) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: uid, switchName, flag: newStatus, secretKey: 'friendly-bohr' })
        }
        fetch('https://dev.blockerx.net/api/updateForceSwitches', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Force switch ddddata : ', data)
            })
            .catch((error) => { })
    }

    const changeForceBuddyRemoveButtonOfUser = (uid, newStatus) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: uid, switchName: 'forceBuddyRemove', flag: newStatus, secretKey: 'friendly-bohr' })
        }
        fetch('https://dev.blockerx.net/api/updateForceSwitches', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Force switch ddddata : ', data)
            })
            .catch((error) => { })
    }
    const changeForceNewlyInstalledSwitch = (uid, newStatus) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: uid, switchName: 'forceNewlyInstalledSwitch', flag: newStatus, secretKey: 'friendly-bohr' })
        }
        fetch('https://dev.blockerx.net/api/updateForceSwitches', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Force switch ddddata : ', data)
            })
            .catch((error) => { })
    }

    const changePreventUninstallStatusOfUser = (uid, status) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: uid, status: status })
        }
        fetch('https://dev.blockerx.net/api/v1/forceUninstall', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setSuccessMessage('Force Prevent Uninstall has been modified')
                    setOpenSuccessAlert(true)
                    return
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 403) {
                    secureLocalStorage.clear()
                    navigate('/forbidden')
                } else {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                }
            })
            .catch((error) => { })
    }

    const getUserInfo = () => {
        navigate(`/userinfo/${userIdentifier}`)
        setUserIdentifier(userIdentifier)
        getUserInformation(userIdentifier)
    }

    const handleGoBack = () => {
        navigate('/userinfo')
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            getUserInfo()
        }
    }

    const navigateToBlocklistOfUser = () => {
        window.open(`/blocklist?uid=${userId}`)
    }

    const navigateToBlockedlistOfUser = () => {
        window.open(`/blocked-items?uid=${userId}`)
    }

    const navigateToVPNBlocklist = () => {
        window.open(`/vpn-blocklist?uid=${userId}`)
    }

    const navigateToPremiumPushPage = () => {
        window.open(`/push-premium?uid=${userId}`)
    }

    const openWhitelistOfUser = () => {
        window.open(`/blockerx-pro-whitelist?uid=${userId}`)
    }

    const viewUserTickets = (userEmail) => {
        window.open(`/customer-tickets/users/${userEmail}`)
    }

    useEffect(() => {
        if (!apiCalled) {
            const path = location.pathname
            const identifer = path.substring(10)
            setUserIdentifier(identifer)
            document.title = 'User - ' + identifer
            console.log(identifer)
            getUserInformation(identifer)
            window.scrollTo(0, 0)
        }
    }, [apiCalled, location.pathname])



    // Force DNS
    const [forceDnsValue, setForceDnsValue] = useState(userDoc.forceDns || '')
    const [modalOpen, setModalOpen] = useState(false)
    const handleEditForceDns = () => {
        setModalOpen(true)
    }

    const handleSaveForceDns = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: userDoc.uid, switchName: 'forceDns', value: forceDnsValue, secretKey: 'friendly-bohr' })
        }
        fetch(`${BASE_URL}/api/v2/dashboard/force-switches`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setUserDoc({ ...userDoc, forceDns: forceDnsValue })
                    setSuccessMessage('Force DNS updated successfully!')
                    setOpenSuccessAlert(true)
                } else {
                    setErrorMessage('Failed to update Force DNS.')
                    setOpenErrorAlert(true)
                }
                setModalOpen(false)
            })
            .catch((error) => {
                setErrorMessage('Failed to update Force DNS.')
                setOpenErrorAlert(true)
                setModalOpen(false)
            })
    }

    return (
        <Box sx={{ flexGrow: 2 }}>
            {!showLoading && !showErrorScreen && (
                <Grid align='center' justify='center' sx={{ marginBottom: '100px' }}>
                    <h1 className='header-top mt-5'>User Information</h1>
                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Input
                            placeholder='Email / User ID'
                            id='combo-box-demo'
                            sx={{ width: '400px' }}
                            onChange={(event, item) => {
                                setUserEmail(event.target.value)
                                setUserIdentifier(event.target.value)
                            }}
                            onKeyDown={handleEnterKey}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Button className='h-12' sx={{ width: '400px' }} onClick={getUserInfo} color='info' variant='contained'>
                            Get Info
                        </Button>
                    </Grid>
                    <Grid>
                        <TableContainer>
                            <Table sx={{ width: '70%', marginTop: 10 }} aria-label='customized table'>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>User ID</StyledTableCell>
                                        <StyledTableCell align='left' className='cursor-pointer' onClick={() => copyUserMailHandler(userId)}>
                                            {userId}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key='dfdfuid112313'>
                                        <StyledTableCell component='th' scope='row'>
                                            Premium
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            {userDoc.premium.status}
                                            {userDoc.premium.status === 'Active' && <WorkspacePremium sx={{ marginLeft: '15px', color: '#efb73e', fontSize: '25px' }} />}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    {userDoc.premium.status !== 'Active' && (
                                        <StyledTableRow key='usernamsdffd22312e'>
                                            <StyledTableCell component='th' scope='row'>
                                                Push Premium
                                            </StyledTableCell>
                                            <StyledTableCell align='left' onClick={() => navigateToPremiumPushPage()}>
                                                <span className='text-blue-500 underline cursor-pointer'>Add Premium Data</span>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}

                                    <StyledTableRow key='dfdsfusername'>
                                        <StyledTableCell component='th' scope='row'>
                                            Username
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.username}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='userfdsname1111'>
                                        <StyledTableCell component='th' scope='row'>
                                            Email
                                        </StyledTableCell>
                                        <StyledTableCell className='cursor-pointer' align='left' onClick={() => copyUserMailHandler(userDoc.email)}>
                                            {userDoc.email}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='userfdsname'>
                                        <StyledTableCell component='th' scope='row'>
                                            Customer Tickets
                                        </StyledTableCell>
                                        <StyledTableCell align='left' onClick={() => viewUserTickets(userDoc.email)}>
                                            <span className='text-blue-500 underline cursor-pointer'>View Tickets</span>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernamfdf2sdfe'>
                                        <StyledTableCell component='th' scope='row'>
                                            Blocklist 📃
                                        </StyledTableCell>
                                        <StyledTableCell align='left' onClick={() => navigateToBlocklistOfUser()}>
                                            <span className='text-blue-500 underline cursor-pointer'>View Blocklist</span>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='1used1df2sdfe'>
                                        <StyledTableCell component='th' scope='row'>
                                            Blocked List 📒
                                        </StyledTableCell>
                                        <StyledTableCell align='left' onClick={() => navigateToBlockedlistOfUser()}>
                                            <span className='text-blue-500 underline cursor-pointer'>View Blocked List</span>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernamfdf2sdfe111'>
                                        <StyledTableCell component='th' scope='row'>
                                            VPN Blocklist 📃
                                        </StyledTableCell>
                                        <StyledTableCell align='left' onClick={() => navigateToVPNBlocklist()}>
                                            <span className='text-blue-500 underline cursor-pointer'>View VPN Blocklist</span>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernfdfsame'>
                                        <StyledTableCell component='th' scope='row'>
                                            Partner
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.partner}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='e323udsernfdfsame'>
                                        <StyledTableCell component='th' scope='row'>
                                            Partner Option
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.partnerOption}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uidaghfh'>
                                        <StyledTableCell component='th' scope='row'>
                                            Plan Name
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.premium.planName}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uidfdgd'>
                                        <StyledTableCell component='th' scope='row'>
                                            Order ID
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.premium.orderId}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uidgfdgds'>
                                        <StyledTableCell component='th' scope='row'>
                                            Purchase Token
                                        </StyledTableCell>
                                        <StyledTableCell className='cursor-pointer' align='left' onClick={() => copyUserMailHandler(userDoc.premium.purchaseToken)}>
                                            {userDoc.premium.purchaseToken.substring(0, 40)}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='fdfdsaf3414'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force DNS
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            {userDoc.forceDns || 'Not Set'}
                                            <Edit
                                                onClick={handleEditForceDns}
                                                sx={{ marginLeft: '10px', cursor: 'pointer' }}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uidgfdgs22213'>
                                        <StyledTableCell component='th' scope='row'>
                                            Payment Method
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.premium.paymentMethod}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernfgsdfgame12'>
                                        <StyledTableCell component='th' scope='row'>
                                            Last Active
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            {showTimeInAgoFormat(userDoc.lastActive)} on {convertMillisecondsToDate(userDoc.lastActive)}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='userngd321gssame'>
                                        <StyledTableCell component='th' scope='row'>
                                            Android Device
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.device}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='userna32megsddgdseee'>
                                        <StyledTableCell component='th' scope='row'>
                                            Android App Version
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.appVersion}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernameg3213sddgds'>
                                        <StyledTableCell component='th' scope='row'>
                                            Android OS Version
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.deviceOsVersion}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernamfdgse2222'>
                                        <StyledTableCell component='th' scope='row'>
                                            iOS Device
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.iosDevice}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='fasfa12314214'>
                                        <StyledTableCell component='th' scope='row'>
                                            iOS App Version
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.iosAppVersion}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='fadfafs3313'>
                                        <StyledTableCell component='th' scope='row'>
                                            iOS OS Version
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{userDoc.iosDeviceOsVersion}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='use21312rnasdgdsgme'>
                                        <StyledTableCell component='th' scope='row'>
                                            Country
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{getCountryOfUser()}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernaaame'>
                                        <StyledTableCell component='th' scope='row'>
                                            Language
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{getLanguageOfUser()}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernams2223dfe'>
                                        <StyledTableCell component='th' scope='row'>
                                            Installed At
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{convertMillisecondsToDate(userDoc.installedAt)}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usern22amfdf2sdfe'>
                                        <StyledTableCell component='th' scope='row'>
                                            Porn Request
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <span className=''>{capitalizeFirstLetter(userDoc.pornRequest)}</span>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usern22amfdf2sdfe'>
                                        <StyledTableCell component='th' scope='row'>
                                            Active Streak
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <span className=''>{getBooleanString(userDoc.hasActiveStreak)}</span>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uidgsdg222'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force Prevent Uninstall
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={userDoc.forceUninstall} onChange={handlePreventUninstallStatus} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='u123idgsfddddg'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force VPN Off
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={userDoc.forceVpn} onChange={handleForceVpnStatus} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='u23211idgsfddddg3121'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force Unsupported Browser
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={userDoc.forceUnsupportedBrowser} onChange={handleForceUnsupportedBrowserStatus} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='u23211idgsfd211123121'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force Buddy Remove
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={userDoc.forceBuddyRemove} onChange={handleForceBuddyRemoveStatus} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='udede1123121'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force Newly Installed Apps
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={userDoc.forceNewlyInstalledSwitch} onChange={handleForceNewlyInstalledStatus} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='udedfdfdfde1123121'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force Instant Approval
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={userDoc.forceInstantApproval} onChange={handleForceInstantApprovalSwitch} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow key='3131fde1123121'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force Change Buddy Remove
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={userDoc.forceChangeBuddyRemove} onChange={handleForceChangeBuddyRemove} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='131134dfde1123121'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force Buddy Trouble Remove
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={userDoc.forceBuddyTroubleRemove} onChange={handleForceBuddyTroubleRemove} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow key='u231sernffffame'>
                                        <StyledTableCell component='th' scope='row'>
                                            Installation Source
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{showInstallationSource(userDoc.installationSource)}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usernagggfsme1312'>
                                        <StyledTableCell component='th' scope='row'>
                                            Chrome Extension User
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{getBooleanString(userDoc.chrome)}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='usemacgggfsme1312'>
                                        <StyledTableCell component='th' scope='row'>
                                            Mac User
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{getBooleanString(userDoc.isMac)}</StyledTableCell>
                                    </StyledTableRow>
                                    {
                                        userDoc.isMac && <>
                                            <StyledTableRow key='ufdafdsernagg333gfsme'>
                                                <StyledTableCell component='th' scope='row'>
                                                    Mac Device Version
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>{userDoc.mac.deviceAppVersion}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='usernaddfdfgg333gfsme'>
                                                <StyledTableCell component='th' scope='row'>
                                                    Mac Device Name
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>{userDoc.mac.deviceName}</StyledTableCell>
                                            </StyledTableRow>
                                        </>
                                    }
                                    {isObjectEmpty(userDoc.blockerxPro) ? (
                                        <StyledTableRow key='usernafa3gggfsme'>
                                            <StyledTableCell component='th' scope='row'>
                                                BlockerX Pro User
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>No</StyledTableCell>
                                        </StyledTableRow>
                                    ) : (
                                        <>
                                            <StyledTableRow key='usernagg111gfsme'>
                                                <StyledTableCell component='th' scope='row'>
                                                    BlockerX Pro Last Active
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>
                                                    {showTimeInAgoFormat(userDoc.blockerxPro.lastActive)} on {convertMillisecondsToDate(userDoc.blockerxPro.lastActive)}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='usernagg333gfsme'>
                                                <StyledTableCell component='th' scope='row'>
                                                    BlockerX Pro Token
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>{userDoc.blockerxPro.token === '' ? 'Not present' : userDoc.blockerxPro.token.substring(0, 40)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='usernaggeeegfsme'>
                                                <StyledTableCell component='th' scope='row'>
                                                    BlockerX Pro Version
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>{userDoc.blockerxPro.version}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='usernagggfsmddde'>
                                                <StyledTableCell component='th' scope='row'>
                                                    BlockerX Pro Whitelist
                                                </StyledTableCell>
                                                <StyledTableCell align='left' onClick={() => openWhitelistOfUser()}>
                                                    <span className='text-blue-500 cursor-pointer underline'>View BlockerX Pro Whitelist</span>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </>
                                    )}
                                    {isObjectEmpty(userDoc.switches.android) ? (
                                        <StyledTableRow key='123124aaad'>
                                            <StyledTableCell component='th' scope='row'>
                                                Android Switches
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>No switches found</StyledTableCell>
                                        </StyledTableRow>
                                    ) : (
                                        <>
                                            {Object.keys(userDoc.switches.android).map((property) => (
                                                <StyledTableRow key={Math.random()}>
                                                    <StyledTableCell key={property} align='left'>
                                                        Android Switch - {transformSwitchName(property)}
                                                    </StyledTableCell>
                                                    <StyledTableCell component='th' scope='row'>
                                                        {userDoc.switches.android[property] ? <Chip label={'On'} sx={{ marginBottom: '4px', cursor: 'pointer' }} color='success' /> : <Chip label={'Off'} sx={{ marginBottom: '4px', cursor: 'pointer' }} color='error' />}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </>
                                    )}
                                    {isObjectEmpty(userDoc.switches.ios) ? (
                                        <StyledTableRow key='123124afadsf'>
                                            <StyledTableCell component='th' scope='row'>
                                                iOS Switches
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>No switches found</StyledTableCell>
                                        </StyledTableRow>
                                    ) : (
                                        <>
                                            {Object.keys(userDoc.switches.ios).map((property) => (
                                                <StyledTableRow key='usernagggfsme141412'>
                                                    <StyledTableCell key={property} align='left'>
                                                        iOS Switch - {transformSwitchName(property)}
                                                    </StyledTableCell>
                                                    <StyledTableCell component='th' scope='row'>
                                                        {userDoc.switches.ios[property] ? <Chip label={'On'} sx={{ marginBottom: '4px', cursor: 'pointer' }} color='success' /> : <Chip label={'Off'} sx={{ marginBottom: '4px', cursor: 'pointer' }} color='error' />}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </>
                                    )}
                                    {isObjectEmpty(userDoc.switches.chrome) ? (
                                        <StyledTableRow key='123124312df'>
                                            <StyledTableCell component='th' scope='row'>
                                                Chrome Switches
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>No switches found</StyledTableCell>
                                        </StyledTableRow>
                                    ) : (
                                        <>
                                            {Object.keys(userDoc.switches.chrome).map((property) => (
                                                <StyledTableRow key='usernagggfsme123213'>
                                                    <StyledTableCell key={property} align='left'>
                                                        Chrome Switch - {transformSwitchName(property)}
                                                    </StyledTableCell>
                                                    <StyledTableCell component='th' scope='row'>
                                                        {userDoc.switches.chrome[property] ? <Chip label={'On'} sx={{ marginBottom: '4px', cursor: 'pointer' }} color='success' /> : <Chip label={'Off'} sx={{ marginBottom: '4px', cursor: 'pointer' }} color='error' />}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}

            {showLoading && !showErrorScreen && (
                <div className='flex items-center justify-center mt-72'>
                    <div className='animate-spin-fast text-center'>
                        <img src={ClockIcon} alt='Warning Icon' className='w-20 h-20 mx-auto' />
                        <p className='header-top my-8'>Loading ...</p>
                    </div>
                </div>
            )}

            {showErrorScreen && (
                <div className='flex items-center justify-center mt-72'>
                    <div className='animate-spin-fast text-center'>
                        <img src={WarningIcon} alt='Warning Icon' className='w-20 h-20 mx-auto' />
                        <p className='header-top my-8'>{errorMessage}</p>
                        <Button className='w-48' variant='contained' color='primary' onClick={handleGoBack}>
                            Go Back
                        </Button>
                    </div>
                </div>
            )}

            {/* Force DNS Edit Modal */}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <TextField
                        label="Force DNS Value"
                        value={forceDnsValue}
                        onChange={(e) => setForceDnsValue(e.target.value)}
                        fullWidth
                    />
                    <Button onClick={handleSaveForceDns} color='primary' variant='contained' sx={{ marginTop: 2 }}>
                        Save
                    </Button>
                    <Button onClick={() => setModalOpen(false)} color='secondary' sx={{ marginTop: 2 }}>
                        Cancel
                    </Button>
                </Box>
            </Modal>

            <Stack sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default UserInfoPage