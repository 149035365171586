import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/Constants';

function BlocklistStatsTable() {
    const [data, setData] = useState([]);

    // Fetch data from API
    const getBlocklistStats = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(`${BASE_URL}/api/v1/dashboard-stats/blocklist-stats`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'success') {
                    setData(result.data); // Save the API data
                }
            })
            .catch((error) => {
                console.log('Error fetching blocklist stats: ', error);
            });
    };

    // Columns for the table
    const columns = [
        {
            Header: 'Category',
            accessor: 'category', // Access the `category` field from the API data
        },
        {
            Header: 'Premium Status',
            accessor: 'isPremium', // Access the `isPremium` field from the API data
        },
        {
            Header: 'Type',
            accessor: 'type', // Access the `type` field from the API data
        },
        {
            Header: 'Count',
            accessor: 'count', // Access the `count` field from the API data
        },
    ];

    // Transform the data to match the format required by the table
    const tableData = data.map((item) => ({
        category: item._id.category,
        isPremium: item._id.isPremium,
        type: item._id.type,
        count: item.count,
    }));

    // Call the API when the component mounts
    useEffect(() => {
        getBlocklistStats();
    }, []);

    return (
        <div className="overflow-x-auto shadow-xl rounded-lg bg-white p-12">
            <div className='text-xl font-bold py-4'>
                Blocklist Stats (Last 30 days)
            </div>
            <table className="min-w-full table-auto text-sm text-gray-700 border">
                <thead className="bg-gray-100 text-gray-600">
                    <tr>
                        {columns.map((column) => (
                            <th key={column.Header} className="px-6 py-3 text-left font-semibold">
                                {column.Header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row, index) => (
                        <tr key={index} className="border-t">
                            {columns.map((column) => (
                                <td key={column.Header} className="px-6 py-4">
                                    {row[column.accessor]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default BlocklistStatsTable;
