const { format } = require("date-fns")
const { showTimeInAgoFormat } = require("./time")

exports.capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

exports.isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(email)
}

exports.getBooleanString = (value) => {
    if (value) {
        return 'Yes'
    }
    return 'No'
}

exports.serializeUnderscoreString = (str) => {
    const formattedString = str.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())
    return formattedString
}

exports.transformSwitchName = (str) => {
    const transformations = {
        swUnSupportedBrowsers: 'Unsupported Browser',
        swPreventUninstall: 'Prevent Uninstall',
        swShoppingAppWebFb: 'Shopping App Web FB',
        swHeart: 'Heart Switch',
        swWebsiteStrictMode: 'Website Strict Mode',
        swSnapchatStories: 'Snapchat Stories',
        swVpn: 'VPN Switch',
        swBlockNotificationArea: 'Block Notification Area',
        SwBlockAllBrowser: 'Block All Browsers'
    }

    if (str in transformations) {
        return transformations[str]
    } else {
        return this.serializeUnderscoreString(str)
    }
}

exports.formatFieldName = (fieldName) => {
    return fieldName
        .replace(/_/g, ' ')             // Replace underscores with spaces
        .replace(/([A-Z])/g, ' $1')     // Add a space before uppercase letters
        .replace(/^./, (str) => str.toUpperCase());  // Capitalize the first letter
};

// Function to format milliseconds to date string
exports.formatDate = (milliseconds) => {
    if (milliseconds === 0) {
        return ''
    }
    return showTimeInAgoFormat(milliseconds) + ' on ' + format(new Date(milliseconds), 'MMMM d, yyyy h:mm a');
};

exports.getLabelById = (id) => {
    const filterItem = this.TICKETS_FILTERS_LIST.find((item) => item.id === id)
    return filterItem ? filterItem.label : 'Label Not Found'
}

exports.TICKETS_FILTERS_LIST = [
    {
        label: 'Pending',
        id: 'pending'
    },
    {
        label: 'Tech Review',
        id: 'tech_review'
    },
    {
        label: 'Tech Complete',
        id: 'tech_complete'
    },
    {
        label: 'Awaiting User Reply',
        id: 'awaiting_user_reply'
    },
    {
        label: 'User Follow Up',
        id: 'user_follow_up'
    },
    {
        label: 'Closed',
        id: 'closed'
    },
    {
        label: 'Silent Closed',
        id: 'silent_closed'
    }
]

exports.DEFAULT_TICKET_REPLY = `Dear user, \n\nThanks for reaching out to us. We apologize for the inconvenience.\n\n\n\nThank you.`

exports.isURL = (str) => {
    try {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
        return urlRegex.test(str.trim())
    } catch {
        return false
    }
}

exports.isVideo = (message) => {
    return message.endsWith('.mp4') || message.endsWith('.avi')
}

exports.isImage = (message) => {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp']
    const lowerCaseMessage = message.toLowerCase()

    return imageExtensions.some((extension) => lowerCaseMessage.endsWith(extension))
}

exports.getAppName = (app) => {
    try {
        return this.capitalizeFirstLetter(app.sourceData.app)
    } catch {
        return 'BlockerX'
    }
}