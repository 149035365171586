const development = false
exports.BASE_URL = development ? 'http://localhost:4000' : 'https://dev.blockerx.net'

const zenze_development = false
exports.ZENZE_BASE_URL = zenze_development ? 'http://localhost:9000' : 'https://zenze-dev.blockerx.net'

const events_development = false
exports.EVENTS_BASE_URL = events_development ? 'http://localhost:9000' : 'https://events.blockerx.net'

const newapp_development = false
exports.NEW_APPS_BASE_URL = newapp_development ? 'http://localhost:4020' : 'https://apps-server.blockerx.net'