import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../api/Constants'

function CommunityPostTypeEachDay() {
  const [data, setData] = useState([])

  const getFeedbackRatingStats = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }

    fetch(`${BASE_URL}/api/v4/dashboard-stats/community-stats`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          processData(data.data.list.reverse())
        }
      })
      .catch((error) => {
        console.log('Error: ', error)
      })
  }

  const processData = (list) => {
    const groupedData = {}

    list.forEach((item) => {
      const monthName = getMonthName(item.month)
      const day = item.day
      const key = `${monthName} ${day}`

      if (!groupedData[key]) {
        groupedData[key] = {
          day: item.day,
          month: monthName,
          postTypes: {},
        }
      }

      if (!groupedData[key].postTypes[item.postType]) {
        groupedData[key].postTypes[item.postType] = 0
      }

      groupedData[key].postTypes[item.postType] += item.count
    })

    // Convert groupedData object to an array to be used in table
    setData(Object.values(groupedData))
  }

  const getMonthName = (monthNumber) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]
    return months[monthNumber - 1] // monthNumber starts from 1, so we subtract 1
  }

  useEffect(() => {
    getFeedbackRatingStats()
  }, [])

  return (
    <div className="overflow-x-auto shadow-xl rounded-lg bg-white p-12">
      <table className="min-w-full table-auto text-sm text-gray-700">
        <thead className="bg-gray-100 text-gray-600">
          <tr>
            <th className="px-6 py-3 text-left font-semibold">Date</th>
            <th className="px-6 py-3 text-left font-semibold">Text Posts</th>
            <th className="px-6 py-3 text-left font-semibold">Image Posts</th>
            <th className="px-6 py-3 text-left font-semibold">Polling Posts</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="border-t">
              <td className="px-6 py-4">
                <span className="font-semibold">{row.month} {row.day}</span>
              </td>
              <td className="px-6 py-4">
                <span className={`text-sm ${row.postTypes.text > 0 ? 'text-gray-800' : 'text-gray-400'}`}>
                  {row.postTypes.text || 0}
                </span>
              </td>
              <td className="px-6 py-4">
                <span className={`text-sm ${row.postTypes.image > 0 ? 'text-gray-800' : 'text-gray-400'}`}>
                  {row.postTypes.image || 0}
                </span>
              </td>
              <td className="px-6 py-4">
                <span className={`text-sm ${row.postTypes.polling > 0 ? 'text-gray-800' : 'text-gray-400'}`}>
                  {row.postTypes.polling || 0}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CommunityPostTypeEachDay
