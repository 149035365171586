import React, { useState } from 'react';
import { Grid, Typography, Paper, styled } from '@mui/material';
import { ArrowBack, Timer } from '@mui/icons-material';
import WebsiteBlockerUsers from './WebsiteBlockerUsers.jsx';
import WebsiteBlockerTopBlocklistWebsites from './TopBlocklistWebsites.jsx';
import WebsiteBlockerTopWhitelistWebsites from './TopWhitelistWebsites.jsx';
import WebsiteBlockerRatingStats from './RatingStats.jsx';
import WebsiteBlockerOnboardingStats from './OnboardingStats.jsx';
import BrowsedSitesStats from './BrowsedSitesStats.jsx';

const items = [
    { name: 'New Users Last 30 days', component: <WebsiteBlockerUsers heading='New users in last 30 days' seriesLabel='users per day' /> },
    { name: 'Top Websites in Blocklist', component: <WebsiteBlockerTopBlocklistWebsites /> },
    { name: 'Top Websites in Whitelist', component: <WebsiteBlockerTopWhitelistWebsites /> },
    { name: 'Rating Stats', component: <WebsiteBlockerRatingStats /> },
    { name: 'Top Onboarding Questions', component: <WebsiteBlockerOnboardingStats /> },
    { name: 'Browsed Sites Count in Last 30 days', component: <BrowsedSitesStats /> },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '16px',
    textAlign: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[1],
        backgroundColor: '#FDFEFE'
    },
}));

function WebsiteBlockerAnalysisHomepage() {
    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleComponentSelect = (component) => {
        setSelectedComponent(component);
    };

    return (
        <div>
            {selectedComponent ? (
                <>
                    <div onClick={() => setSelectedComponent(null)} className='text-left px-16 pt-8 text-2xl capitalize cursor-pointer'>
                        <ArrowBack /> Home
                    </div>
                    <div>{selectedComponent}</div>
                </>
            ) : (
                <>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ pt: 4, textAlign: 'center', fontWeight: 'bolder' }}
                    >
                        Website Blocker Analysis
                    </Typography>
                    <Grid container spacing={3} sx={{ p: 4 }}>
                        {items.map((item, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <StyledPaper
                                    sx={{ cursor: 'pointer' }}
                                    elevation={3}
                                    onClick={() => handleComponentSelect(item.component)}
                                >
                                    <Typography variant="h6">
                                        {item.name}
                                    </Typography>
                                </StyledPaper>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </div>
    );
}

export default WebsiteBlockerAnalysisHomepage;
