import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../../api/Constants';

function SpamTicket({ email }) {
    const [isSpam, setIsSpam] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Function to fetch the spam status
        const fetchSpamStatus = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/v1/tickets-ops/spam-users/${email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();

                if (result.message === 'success') {
                    setIsSpam(result.data.spam);
                } else {
                    setError(result.message || 'Failed to fetch spam status');
                }
            } catch (error) {
                console.error('Error:', error);
                setError('Failed to fetch spam status');
            } finally {
                setLoading(false);
            }
        };

        fetchSpamStatus();
    }, [email]);

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div>
            {isSpam && (
                <span className="inline-block px-4 py-2 text-sm text-white bg-red-600 rounded-full">
                    Spam
                </span>
            )}
        </div>
    );
}

export default SpamTicket;
