import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { getDayMonthFromMs } from '../../../Utils/time';
import { BASE_URL } from '../../../api/Constants';

function CommonAnalysisComponentV2({
    heading = 'Community Posts (Premium vs Non-Premium)',
    type = 'monthly_community_stats',
    seriesLabelPremium = 'Premium Users',
    seriesLabelNonPremium = 'Non-Premium Users'
}) {
    const [apiCalled, setApiCalled] = useState(false);
    const [labelArray, setLabelArray] = useState([]);
    const [premiumCountArray, setPremiumCountArray] = useState([]);
    const [nonPremiumCountArray, setNonPremiumCountArray] = useState([]);
    const [chartType, setChartType] = useState('bar');

    const handleTicketStats = (ticketStatsList) => {
        const labels = [];
        const premiumCounts = [];
        const nonPremiumCounts = [];

        ticketStatsList.forEach((ticket) => {
            labels.push(getDayMonthFromMs(ticket._id));
            premiumCounts.push(ticket.premiumCount);
            nonPremiumCounts.push(ticket.nonPremiumCount);
        });

        setLabelArray(labels);
        setPremiumCountArray(premiumCounts);
        setNonPremiumCountArray(nonPremiumCounts);
    };

    const getFeedbackRatingStats = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(`${BASE_URL}/api/v3/dashboard-stats/community-stats?type=${type}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data);
                if (data.status === 200) {
                    setApiCalled(true);
                    handleTicketStats(data.data.list);
                }
            })
            .catch((error) => {
                console.log('Error : ', error);
            });
    };

    useEffect(() => {
        if (!apiCalled) {
            getFeedbackRatingStats();
        }
    }, [apiCalled]);

    const toggleChartType = () => {
        setChartType((prevType) => (prevType === 'bar' ? 'line' : 'bar'));
    };

    return (
        <>
            <div>
                <p className="text-center text-4xl font-bold mt-12">{heading}</p>
                <div className="text-center mt-4">
                    <button
                        onClick={toggleChartType}
                        className="bg-gray-700 text-white px-4 py-2 rounded"
                    >
                        Toggle to {chartType === 'bar' ? 'Line' : 'Bar'} Chart
                    </button>
                </div>
            </div>
            {labelArray.length > 0 && (
                <div className="pt-8">
                    {chartType === 'bar' ? (
                        <BarChart
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: labelArray,
                                    scaleType: 'band'
                                }
                            ]}
                            series={[
                                {
                                    data: premiumCountArray,
                                    label: seriesLabelPremium,
                                    color: '#41a043' // Preferred premium color
                                },
                                {
                                    data: nonPremiumCountArray,
                                    label: seriesLabelNonPremium,
                                    color: '#33414d' // Default non-premium color
                                }
                            ]}
                            height={500}
                            sx={{ margin: '10px' }}
                        />
                    ) : (
                        <LineChart
                            xAxis={[
                                {
                                    id: 'lineCategories',
                                    data: labelArray,
                                    scaleType: 'point'
                                }
                            ]}
                            series={[
                                {
                                    data: premiumCountArray,
                                    label: seriesLabelPremium,
                                    color: '#41a043'
                                },
                                {
                                    data: nonPremiumCountArray,
                                    label: seriesLabelNonPremium,
                                    color: '#33414d'
                                }
                            ]}
                            height={500}
                            sx={{ margin: '10px' }}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default CommonAnalysisComponentV2;
