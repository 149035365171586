import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NEW_APPS_BASE_URL } from '../../../api/Constants';

const WebsiteBlockerPremiumData = ({ uid }) => {
    const [premiumData, setPremiumData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (uid) {
            fetchPremiumData();
        }
    }, [uid]);

    const fetchPremiumData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${NEW_APPS_BASE_URL}/api/v1/website-blocker/premium-data?uid=${uid}`);
            if (response.data.status === 200) {
                setPremiumData(response.data.data.data);
            } else {
                setError('Failed to fetch premium data');
            }
        } catch (err) {
            setError('An error occurred while fetching the data');
        } finally {
            setLoading(false);
        }
    };

    const deletePremiumData = async (id) => {
        try {
            const response = await axios.delete(`${NEW_APPS_BASE_URL}/api/v1/website-blocker/premium-data/${id}`);
            if (response.data.status === 200) {
                setPremiumData((prevData) => prevData.filter((item) => item._id !== id));
            } else {
                setError('Failed to delete premium data');
            }
        } catch (err) {
            setError('An error occurred while deleting the data');
        }
    };

    return (
        <div className="mt-6 overflow-x-auto">
            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}

            {premiumData.length > 0 ? (
                <table className="min-w-full table-auto bg-gray-50 shadow-md rounded-lg">
                    <thead>
                        <tr className="bg-blue-500 text-white">
                            <th className="px-6 py-3 text-left">Plan Name</th>
                            <th className="px-6 py-3 text-left">Order ID</th>
                            <th className="px-6 py-3 text-left">Status</th>
                            <th className="px-6 py-3 text-left">Purchased At</th>
                            <th className="px-6 py-3 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700">
                        {premiumData.map((item) => (
                            <tr key={item._id} className="border-b hover:bg-gray-100">
                                <td className="px-6 py-4">{item.planName}</td>
                                <td className="px-6 py-4">{item.orderID}</td>
                                <td className="px-6 py-4">{item.status}</td>
                                <td className="px-6 py-4">{new Date(item.purchasedAt).toLocaleString()}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => deletePremiumData(item._id)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No premium data found</p>
            )}
        </div>
    );
};

export default WebsiteBlockerPremiumData;
