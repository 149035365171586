import './wronglyBlocked.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/joy/Typography';
import DatePicker from 'react-datepicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import secureLocalStorage from 'react-secure-storage';
import { BASE_URL } from '../../api/Constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';

function WronglyBlockedChromeExtensionReport() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [revenueList, setRevenueList] = useState([])
    const [dailyWiseRevenue, setDailyWiseRevenue] = useState({})
    const [totalStripeRevenue, setTotalStripeRevenue] = useState(0)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [minDate, setMinDate] = useState(new Date("2024-05-01"))
    const [maxDate, setMaxDate] = useState(new Date("2034-05-22"))
    const [product, setProduct] = useState('All')
    const [category, setCategory] = useState('All')
    const [country, setCountry] = useState('All')
    const [adultlistWebsiteCount, setAdultlistWebsiteCount] = useState(0)
    const [adultlistKeywordCount, setAdultlistKeywordCount] = useState(0)
    const [blocklistWebsiteCount, setBlocklistWebsiteCount] = useState(0)
    const [blocklistKeywordCount, setBlocklistKeywordCount] = useState(0)

    const categoryChange = (event) => {
        setCategory(event.target.value);
    };
    const productChange = (event) => {
        setProduct(event.target.value);
    };
    const countryChange = (event) => {
        setCountry(event.target.value);
    };

    const onDateRangeChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        console.log(' Checking...', { start, end })
    };

    const filtersApplied = () => {
        setLoading(true)
        console.log('This is end date', endDate)
        const nextDay = new Date(endDate)
        nextDay.setDate(nextDay.getDate() + 1);
        getWronglyBlockedInfo(formatDate(startDate), formatDate(nextDay))
    }

    const getWronglyBlockedInfo = async (startDate, endDate) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    startDate,
                    endDate,
                    secretKey: 'monkeyDLuffy'
                })
            };
            console.log({ requestOptions })
            const res = await fetch('https://dev.blockerx.net/api/chrome/wrongly-blocked', requestOptions)
            // console.log('Res : ', res)
            if (res.status === 403) {
                console.log('Unauthorized : ', 'hehe')
                toast.error('You are unauthorized to view Stripe analysis', {
                    position: 'bottom-left'
                })
                secureLocalStorage.clear()
                navigate('/forbidden')
            } else if (res.status === 401) {
                secureLocalStorage.clear()
                navigate('/forbidden')
            }
            let BLOCKED_DATA = await res.json()
            BLOCKED_DATA = BLOCKED_DATA.data
            console.log({ BLOCKED_DATA })
            console.log('Before count', BLOCKED_DATA.length)
            BLOCKED_DATA = BLOCKED_DATA.filter((singleBlocked) => {
                if (singleBlocked.time && singleBlocked.type && singleBlocked.category) {
                    return true
                }
                return false
            })
            console.log('AFTER count', BLOCKED_DATA.length)
            const BLOCKED_DATA_TYPE_WISE = Object.groupBy(BLOCKED_DATA, ({ type }) => type)
            Object.keys(BLOCKED_DATA_TYPE_WISE).forEach((key) => {
                const BLOCKED_DATA_CATEGORY_WISE = Object.groupBy(BLOCKED_DATA_TYPE_WISE[key], ({ category }) => category)
                if (key === 'adultlist') {
                    if ('website' in BLOCKED_DATA_CATEGORY_WISE && BLOCKED_DATA_CATEGORY_WISE.website) {
                        setAdultlistWebsiteCount(BLOCKED_DATA_CATEGORY_WISE.website.length)
                    }
                    if ('keyword' in BLOCKED_DATA_CATEGORY_WISE && BLOCKED_DATA_CATEGORY_WISE.keyword) {
                        setAdultlistKeywordCount(BLOCKED_DATA_CATEGORY_WISE.keyword.length)
                    }
                } else if (key === 'blocklist') {
                    if ('website' in BLOCKED_DATA_CATEGORY_WISE && BLOCKED_DATA_CATEGORY_WISE.website) {
                        setBlocklistWebsiteCount(BLOCKED_DATA_CATEGORY_WISE.website.length)
                    }
                    if ('keyword' in BLOCKED_DATA_CATEGORY_WISE && BLOCKED_DATA_CATEGORY_WISE.keyword) {
                        setBlocklistKeywordCount(BLOCKED_DATA_CATEGORY_WISE.keyword.length)
                    }
                }
                console.log('For ', key, 'list is', BLOCKED_DATA_CATEGORY_WISE)
            })
            const COUNT_GROUPED_BY_DATE = groupByDay(BLOCKED_DATA)
            const DATE_LIST = getDatesBetween(startDate, endDate)
            const DAILY_COUNT_LIST = {
                'dates': DATE_LIST,
                'Count': [],
                'Adultlist Website': [],
                'Adultlist Keyword': [],
                'Blocklist Website': [],
                'Blocklist Keyword': []
            }
            for (let i = 0; i < DATE_LIST.length; i++) {
                let daily_count = 0
                let daily_adultlist_website_count = 0
                let daily_adultlist_keyword_count = 0
                let daily_blocklist_website_count = 0
                let daily_blocklist_keyword_count = 0

                const COUNT_OF_THE_DAY = COUNT_GROUPED_BY_DATE[DATE_LIST[i]]

                if (COUNT_OF_THE_DAY && COUNT_OF_THE_DAY !== undefined && COUNT_OF_THE_DAY.length) {
                    // console.log('For Date', DATE_LIST[i], 'RESULT', COUNT_OF_THE_DAY)
                    daily_count = COUNT_OF_THE_DAY.length
                    const TYPE_WISE_COUNT_OF_THE_DAY = Object.groupBy(COUNT_OF_THE_DAY, ({ type }) => type)
                    Object.keys(TYPE_WISE_COUNT_OF_THE_DAY).forEach((key) => {
                        const CATEGORY_WISE_COUNT_OF_THE_DAY = Object.groupBy(TYPE_WISE_COUNT_OF_THE_DAY[key], ({ category }) => category)
                        // console.log('For Date', DATE_LIST[i], 'For Key', key, 'Result', CATEGORY_WISE_COUNT_OF_THE_DAY)
                        if (key === 'adultlist') {
                            if (CATEGORY_WISE_COUNT_OF_THE_DAY && CATEGORY_WISE_COUNT_OF_THE_DAY!== undefined && 'website' in CATEGORY_WISE_COUNT_OF_THE_DAY && CATEGORY_WISE_COUNT_OF_THE_DAY['website']) {
                                daily_adultlist_website_count = CATEGORY_WISE_COUNT_OF_THE_DAY['website'].length
                            }
                            if (CATEGORY_WISE_COUNT_OF_THE_DAY && CATEGORY_WISE_COUNT_OF_THE_DAY!== undefined && 'keyword' in CATEGORY_WISE_COUNT_OF_THE_DAY && CATEGORY_WISE_COUNT_OF_THE_DAY['keyword']) {
                                daily_adultlist_keyword_count = CATEGORY_WISE_COUNT_OF_THE_DAY['keyword'].length
                            }
                        } else if (key === 'blocklist') {
                            if (CATEGORY_WISE_COUNT_OF_THE_DAY && CATEGORY_WISE_COUNT_OF_THE_DAY!== undefined && 'website' in CATEGORY_WISE_COUNT_OF_THE_DAY && CATEGORY_WISE_COUNT_OF_THE_DAY['website']) {
                                daily_blocklist_website_count = CATEGORY_WISE_COUNT_OF_THE_DAY['website'].length
                            }
                            if (CATEGORY_WISE_COUNT_OF_THE_DAY && CATEGORY_WISE_COUNT_OF_THE_DAY!== undefined && 'keyword' in CATEGORY_WISE_COUNT_OF_THE_DAY && CATEGORY_WISE_COUNT_OF_THE_DAY['keyword']) {
                                daily_blocklist_keyword_count = CATEGORY_WISE_COUNT_OF_THE_DAY['keyword'].length
                            }
                        }
                    })
                }
                DAILY_COUNT_LIST['Count'].push(daily_count)
                DAILY_COUNT_LIST['Adultlist Website'].push(daily_adultlist_website_count)
                DAILY_COUNT_LIST['Adultlist Keyword'].push(daily_adultlist_keyword_count)
                DAILY_COUNT_LIST['Blocklist Website'].push(daily_blocklist_website_count)
                DAILY_COUNT_LIST['Blocklist Keyword'].push(daily_blocklist_keyword_count)
            }
            setDailyWiseRevenue(DAILY_COUNT_LIST)
            setLoading(false)

        } catch (err) {
            console.log('Err : ', err)
        }

    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-indexed, and pad with zero if needed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const groupByDay = (arr) => {
        return arr.reduce((acc, obj) => {
            const thisTime = parseInt(obj.time)
            const date = new Date(thisTime);
            const day = date.toISOString().split('T')[0]; // Get the date in YYYY-MM-DD format

            if (!acc[day]) {
                acc[day] = [];
            }

            acc[day].push(obj);
            return acc;
        }, {});
    };

    function getDatesBetween(startDate, endDate) {
        const dateArray = [];
        let currentDate = new Date(startDate);
        let end = new Date(endDate);

        while (currentDate < end) {
            dateArray.push(new Date(currentDate).toISOString().slice(0, 10)); // Formats the date to "YYYY-MM-DD"
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    }


    useEffect(() => {
        const currentDate = new Date();

        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

        console.log("First Day of the Month:", formatDate(firstDay))
        console.log("Last Day of the Month:", formatDate(lastDay))
        setMaxDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
        setStartDate(firstDay)
        setEndDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
        // getRevenueInfo(formatDate(firstDay), formatDate(lastDay), "All", "All", "All")
        getWronglyBlockedInfo(formatDate(firstDay), formatDate(lastDay))
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <h1 className='header-top mt-5'>Chrome Extension- Wrongly Blocked Report</h1>
            <div className='revenue_filters'>
                <DatePicker
                    selected={startDate}
                    onChange={onDateRangeChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={minDate}
                    maxDate={maxDate}
                />
                <Button onClick={filtersApplied} variant="contained">Apply Filters</Button>
                {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ padding: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 700 }}>
                    <Table sx={{ maxWidth: 600 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Types</TableCell>
                                <TableCell align="right">Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    Adultlist Website
                                </TableCell>
                                <TableCell align="right">{adultlistWebsiteCount ? adultlistWebsiteCount : 0}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    Adultlist Keyword
                                </TableCell>
                                <TableCell align="right">{adultlistKeywordCount ? adultlistKeywordCount : 0}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    Blocklist Website
                                </TableCell>
                                <TableCell align="right">{blocklistWebsiteCount ? blocklistWebsiteCount : 0}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    Blocklist Keyword
                                </TableCell>
                                <TableCell align="right">{blocklistKeywordCount ? blocklistKeywordCount : 0}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography level="h2">Total Count: {adultlistWebsiteCount + adultlistKeywordCount + blocklistWebsiteCount + blocklistKeywordCount} </Typography>
                </TableContainer >
            )}
            </div>
            <h1 className='header-top mt-5'>Bar Graph- Segmented by Type</h1>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className='stripe_revenue_graph_container'>
                    <BarChart
                        height={600}
                        series={[
                            { data: dailyWiseRevenue['Adultlist Website'], label: 'Adultlist Website', id: 'Adultlist WebsiteId', stack: 'total' },
                            { data: dailyWiseRevenue['Adultlist Keyword'], label: 'Adultlist Keyword', id: 'Adultlist KeywordId', stack: 'total' },
                            { data: dailyWiseRevenue['Blocklist Website'], label: 'Blocklist Website', id: 'Blocklist WebsiteId', stack: 'total' },
                            { data: dailyWiseRevenue['Blocklist Keyword'], label: 'Blocklist Keyword', id: 'Blocklist KeywordId', stack: 'total' }
                        ]}
                        xAxis={[{ data: dailyWiseRevenue.dates, scaleType: 'band' }]}
                    />
                </div>
            )}
            <h1 className='header-top mt-5'>Blocked Data Line Graph</h1>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className='stripe_revenue_graph_container'>
                    <LineChart
                        xAxis={[{ data: dailyWiseRevenue.dates, scaleType: 'point' }]}
                        series={[
                            { data: dailyWiseRevenue['Count'], label: 'Count', id: 'Count' }
                        ]}
                        height={600}
                    />
                </div>
            )}
        </Box >
    )
}

export default WronglyBlockedChromeExtensionReport