import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { BASE_URL } from '../../../api/Constants';

function RedeemCoinLogsTable() {
    const [data, setData] = useState([]);

    // Fetch data from API
    const getRedeemCoinLogs = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(`${BASE_URL}/api/v1/dashboard-stats/redeem-coin-logs`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'success') {
                    setData(result.data); // Save the API data
                }
            })
            .catch((error) => {
                console.log('Error fetching redeem coin logs: ', error);
            });
    };

    // Columns for the table
    const columns = React.useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action', // Access the `action` field from the API data
            },
            {
                Header: 'Premium True Count',
                accessor: 'premium_true', // Access the `premium_true` field from the API data
                sortType: 'basic', // Enable sorting for this column
            },
            {
                Header: 'Premium False Count',
                accessor: 'premium_false', // Access the `premium_false` field from the API data
                sortType: 'basic', // Enable sorting for this column
            },
            {
                Header: 'Total Count',
                accessor: 'total_count', // Access the `total_count` field from the API data
                sortType: 'basic', // Enable sorting for this column
            },
        ],
        []
    );

    // Initialize React Table with data and columns
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy // Enable sorting
    );

    // Call the API when the component mounts
    useEffect(() => {
        getRedeemCoinLogs();
    }, []);

    return (
        <div className="overflow-x-auto shadow-xl rounded-lg bg-white p-6">
            <div className='text-xl font-bold py-4'>
                Redeem Coins Logs Table - Last 30 days
            </div>
            <table {...getTableProps()} className="min-w-full table-auto text-sm text-gray-700 p-8">
                <thead className="bg-gray-100 text-gray-600">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="px-6 py-3 text-left font-semibold"
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className="border-t">
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()} className="px-6 py-4">
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default RedeemCoinLogsTable;
