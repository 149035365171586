import React, { useState } from 'react';
import { Grid, Typography, Paper, styled } from '@mui/material';
import { ArrowBack, Timer } from '@mui/icons-material';
import CommonAnalysisComponent from './CommonAnalysisComponent.jsx';
import CommonAnalysisComponentV2 from './CommonAnalysisComponentV2.jsx';
import CommunityPostTypeEachDay from './CommunityPostTypeEachDay.jsx';

const items = [
    { name: 'Posts', component: <CommonAnalysisComponent heading='Posts in last 30 days' seriesLabel='posts per day' type='monthly_posts' />, icon: <Timer /> },
    { name: 'Upvotes', component: <CommonAnalysisComponent heading='Upvotes in last 30 days' seriesLabel='upvotes per day' type='monthly_upvotes' />, icon: <Timer /> },
    { name: 'Downvotes', component: <CommonAnalysisComponent heading='Downvotes in last 30 days' seriesLabel='downvotes per day' type='monthly_downvotes' />, icon: <Timer /> },
    { name: 'Reports', component: <CommonAnalysisComponent heading='Post Reports in last 30 days' seriesLabel='reports per day' type='monthly_reports' />, icon: <Timer /> },
    { name: 'Comments', component: <CommonAnalysisComponent heading='Comments in last 30 days' seriesLabel='comments per day' type='monthly_comments' />, icon: <Timer /> },
    
    // New Items using V2 (Premium vs Non-Premium)
    { name: 'Premium vs Non-Premium Posts', component: <CommonAnalysisComponentV2 heading='Posts (Premium vs Non-Premium)' type='monthly_posts' />, icon: <Timer /> },
    { name: 'Premium vs Non-Premium Upvotes', component: <CommonAnalysisComponentV2 heading='Upvotes (Premium vs Non-Premium)' type='monthly_upvotes' />, icon: <Timer /> },
    { name: 'Premium vs Non-Premium Downvotes', component: <CommonAnalysisComponentV2 heading='Downvotes (Premium vs Non-Premium)' type='monthly_downvotes' />, icon: <Timer /> },
    { name: 'Premium vs Non-Premium Reports', component: <CommonAnalysisComponentV2 heading='Reports (Premium vs Non-Premium)' type='monthly_reports' />, icon: <Timer /> },
    { name: 'Premium vs Non-Premium Comments', component: <CommonAnalysisComponentV2 heading='Comments (Premium vs Non-Premium)' type='monthly_comments' />, icon: <Timer /> },
    
    // Post Type Table
    { name: 'Community Post Type Each Day', component: <CommunityPostTypeEachDay/>, icon: <Timer /> },
    
];

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '16px',
    textAlign: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[1],
        backgroundColor: '#FDFEFE'
    },
}));

function CommunityAnalysisHomepage() {
    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleComponentSelect = (component) => {
        setSelectedComponent(component);
    };

    return (
        <div>
            {selectedComponent ? (
                <>
                    <div onClick={() => setSelectedComponent(null)} className='text-left px-16 pt-8 text-2xl capitalize cursor-pointer'>
                        <ArrowBack /> Home
                    </div>
                    <div>{selectedComponent}</div>
                </>
            ) : (
                <>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ pt: 4, textAlign: 'center', fontWeight: 'bolder' }}
                    >
                        Community Analysis
                    </Typography>
                    <Grid container spacing={3} sx={{ p: 4 }}>
                        {items.map((item, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <StyledPaper
                                    sx={{ cursor: 'pointer' }}
                                    elevation={3}
                                    onClick={() => handleComponentSelect(item.component)}
                                >
                                    <Typography variant="h6">
                                        {item.name}
                                        <span className='pl-4'>{item.icon}</span>
                                    </Typography>
                                </StyledPaper>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </div>
    );
}

export default CommunityAnalysisHomepage;
