import React, { useState } from 'react';
import axios from 'axios';
import { NEW_APPS_BASE_URL } from '../../../api/Constants';
import WebsiteBlockerPremiumData from './premium-data';

const WebsiteBlockerUserInfo = () => {
    const [uid, setUid] = useState('');
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchUserProfile = async () => {
        if (!uid) {
            setError('UID is required');
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${NEW_APPS_BASE_URL}/api/v1/website-blocker/user-profile?uid=${uid}`);
            if (response.data.status === 200) {
                setUserData(response.data.data.user);
            } else {
                setError('Failed to fetch user profile');
            }
        } catch (err) {
            setError('An error occurred while fetching the data');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-4">
            <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">User Profile</h2>
            <div className="mb-4">
                <label className="block text-lg font-medium mb-2 text-gray-700">Enter User UID:</label>
                <input
                    type="text"
                    value={uid}
                    onChange={(e) => setUid(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <button
                onClick={fetchUserProfile}
                className="w-full bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600 focus:outline-none transition duration-300"
                disabled={loading}
            >
                {loading ? 'Loading...' : 'Fetch Profile'}
            </button>

            {error && <p className="mt-4 text-red-500">{error}</p>}

            {userData && (
                <div className="mt-6 overflow-x-auto">
                    <table className="min-w-full table-auto bg-gray-50 shadow-md rounded-lg">
                        <thead>
                            <tr className="bg-blue-500 text-white">
                                <th className="px-6 py-3 text-left">Field</th>
                                <th className="px-6 py-3 text-left">Value</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            <tr className="border-b hover:bg-gray-100">
                                <td className="px-6 py-4 font-semibold">Username</td>
                                <td className="px-6 py-4">{userData.username}</td>
                            </tr>
                            <tr className="border-b hover:bg-gray-100">
                                <td className="px-6 py-4 font-semibold">Email</td>
                                <td className="px-6 py-4">{userData.email}</td>
                            </tr>
                            <tr className="border-b hover:bg-gray-100">
                                <td className="px-6 py-4 font-semibold">Provider</td>
                                <td className="px-6 py-4">{userData.provider}</td>
                            </tr>
                            <tr className="border-b hover:bg-gray-100">
                                <td className="px-6 py-4 font-semibold">Device</td>
                                <td className="px-6 py-4">{userData.android.device}</td>
                            </tr>
                            <tr className="border-b hover:bg-gray-100">
                                <td className="px-6 py-4 font-semibold">Country</td>
                                <td className="px-6 py-4">{userData.android.country}</td>
                            </tr>
                            <tr className="border-b hover:bg-gray-100">
                                <td className="px-6 py-4 font-semibold">Premium Plan</td>
                                <td className="px-6 py-4">{userData.premium[0]?.planName || 'N/A'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            <WebsiteBlockerPremiumData uid={uid}/>
        </div>
    );
};

export default WebsiteBlockerUserInfo;
