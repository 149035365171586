import React, { useEffect, useState } from 'react'
import { NEW_APPS_BASE_URL } from '../../../api/Constants'

function WebsiteBlockerOnboardingStats() {
    const [onboardingStats, setOnboardingStats] = useState([])

    const getOnboardingStats = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }

        fetch(`${NEW_APPS_BASE_URL}/api/v1/website-blocker/stats/onboarding-stats`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setOnboardingStats(data.data.list)
                }
            })
            .catch((error) => {
                console.log('Error:', error)
            })
    }

    useEffect(() => {
        getOnboardingStats()
    }, [])

    return (
        <div className="overflow-x-auto py-6 px-4">
            <p className="text-center text-2xl text-gray-500 font-bold mt-12">Onboarding Stats</p>
            <div className="mt-8 w-full overflow-hidden bg-white sm:rounded-lg">
                <table className="mx-auto divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Question
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Answer
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Count
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {onboardingStats.map((stat, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stat.questionID}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stat.answer}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stat.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WebsiteBlockerOnboardingStats
